(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay2/assets/javascripts/product-sizes.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay2/assets/javascripts/product-sizes.js');
"use strict";


const allProductSizes = ['fulltraff-48', 'fulltraff-64', 'fulltraff-96', 'fulltraff-128', 'fulltraff-180', 'fulltraff-256', 'stryktipset-48', 'stryktipset-64', 'stryktipset-96', 'stryktipset-128', 'stryktipset-256', 'europatipset-48', 'europatipset-64', 'europatipset-96', 'europatipset-128', 'europatipset-256', 'topptipset-32', 'topptipset-64', 'topptipset-108', 'powerplay-48', 'powerplay-64', 'powerplay-96', 'powerplay-128', 'powerplay-256', 'challenge-40', 'challenge-60', 'challenge-80', 'challenge-120', 'challenge-160', 'bomben-40', 'bomben-60', 'bomben-100', 'maltipset-22.5', 'maltipset-112.5', 'maltipset-412.5'];

function getProductSizes(product) {
  const matchingProductSizes = allProductSizes.filter(size => size.split('-')[0] === product);
  const productSizes = matchingProductSizes.map(size => size.split('-')[1]);
  return productSizes;
}

const toExport = {
  getProductSizes
};

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.banner_layouts.griditem_quickplay2.product_sizes', toExport);
}


 })(window);